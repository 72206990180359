let isEditMode = window.location.search.match(/editmode=true/)

import {component} from "duo-kit"
import Accessibilty from "./svelte/accessibility.svelte"
component({component:Accessibilty,tagname:"duo-access",href:"/resources/css/site.css",shadow:isEditMode})

var msnry, riot;

window.riot = riot = require('riot');

require('riot-kit/lib/slideshow');
require("riot-kit/lib/img_loader");
require('riot-kit/lib/form');
require('riot-kit/lib/twitter');
require('riot-kit/lib/modal');
require('riot-kit/lib/form');
require('riot-kit/lib/ecommerce');
require('riot-kit/lib/pagination');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/booking-calendar');
require('riot-kit/lib/dropdown');
require('riot-kit/lib/gmap');
require('riot-kit/lib/auth/forgot-password');
require('riot-kit/lib/ical');

require('./tags/loginbar.tag');
require('./tags/ecommercebar.tag');
require('./tags/navigation.tag');
require('./tags/mydetails.tag');
require('./tags/mypassword.tag');
require('./tags/signup.tag');
require('./tags/tags-input.tag');
require('./tags/comments_input.tag');
require('./tags/comments_output.tag');
require('./tags/recaptcha.tag');
require('./tags/pricetag.tag');
require('./libs/slideshow.coffee');
require('./tags/duomap.tag');
require('./tags/welcomevid.tag');
require('./tags/twitter.tag');
require('./tags/bramblemap.tag');
require('./tags/ticker.tag');

require('./tags/videomodal.tag');

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow,gmap');
});

msnry = '';

window.onload = function() {
  return setTimeout(function() {
    var imgLoad;
    msnry = $('.twitter-inner').masonry({
      itemSelector: '.item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });
    imgLoad = imagesLoaded('.twitter-inner');
    imgLoad.on('progress', (function(_this) {
      return function() {
        return msnry.masonry('layout');
      };
    })(this));
    return imgLoad.on('always', (function(_this) {
      return function() {
        var ti;
        ti = document.querySelectorAll('.twitter-inner,.twitter-loading');
        return [].forEach.call(ti, function(t) {
          return t.className = t.className.replace(/ ?loaded ?/, '') + ' loaded';
        });
      };
    })(this));
  }, 1000);
};

// var vidlinks;

// vidlinks = document.querySelectorAll('.figure-videolink,.videolink');

// if (vidlinks) {
//   console.log(vidlinks);
//   [].map.call(vidlinks, (function(_this) {
//     return function(vidlink) {
//       var link;
//       link = vidlink.querySelector('a[href]');
//       if (link) {
//         console.log("link",link);
//         return vidlink.addEventListener('click', function(e) {
//           console.log("click");
//           e.preventDefault();
//           return link.click();
//         });
//       }
//     };
//   })(this));
// }