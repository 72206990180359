
    var riot = require('riot')
    
riot.tag2('bramblemap', '<gmap-markers markers="{markers1}" linkedmap="{opts.linkedmap}"></gmap-markers>', '', '', function(opts) {
this.markers1 = [
  {
    name: "NG19 8DF",
    latitude: 53.164798,
    longitude: -1.198578
  }
];
});
    
  