<script>
  import {onMount} from 'svelte'
  import {Dropdown,Button} from "duo-kit"
  export let active,toggle,size,init
  export let classes = []

  onMount(async () =>{
    classes = document.documentElement.classList.value.split(' ').filter((el)=> el)
    size = document.body.className.search(/size[0-9]{1}/g)>-1 ? document.body.className.match(/size[0-9]{1}/g)[0].replace(/size/,'') : null
    setTimeout(() => {
      init = true;
    },3000);
  })

  const setSize = (val)=>{
    size = val
    document.cookie = "size="+val+"; path=/"
    let classname = document.body.className.replace(/ ?size[0-9] ?/,"")
    if(val) classname+=" size"+val 
    document.body.className = classname
  }

  const toggleClass = (val)=>{
    document.documentElement.classList.toggle(val,toggle)
    document.cookie = "duoaccess="+document.documentElement.className+"; path=/"
    classes = document.documentElement.classList.value.split(' ')
  }

  const reset = ()=>{
    document.documentElement.className = document.documentElement.className.replace(/duo-access-[a-zA-Z]*/gi,'')
    document.cookie = "duoaccess=; path=/"
    classes = []
  }

</script>

<div class="duo-access {active ? 'active':''}">
  <button on:click="{() => active = !active}" class={classes.length > 0 && init!=true ? 'shake' : ''}>
    <span class="fa fa-universal-access"></span>
  </button>
  <ul>
    <li>
      <Dropdown>
        <Button mode="default">
          <span class="fa fa-search-plus"></span>
          <span style="font-size:14px">A</span>
          <span style="font-size:16px">A</span>
          <span style="font-size:18px">A</span>
        </Button>
        <div slot="menu">
          <li><button on:click={()=> setSize("")}>Normal {#if !size}<span class="fa fa-check" />{/if}</button></li>
          <li><button on:click={()=> setSize("2")}>Large {#if size=="2"}<span class="fa fa-check" />{/if}</button></li>
          <li><button on:click={()=> setSize("3")}>Larger {#if size=="3"}<span class="fa fa-check" />{/if}</button></li>
        </div>
      </Dropdown>
    </li>
    <!-- <li><button on:click={()=> toggleClass('duo-access-textup')}><span class="fa fa-search-plus"></span> Text increase</button></li>
    <li><button on:click={()=> toggleClass('duo-access-textdown')}><span class="fa fa-search-minus"></span> Text decrease</button></li> -->
    <li class={classes.indexOf('duo-access-greyscale')>-1 ? 'active' : ''}><button on:click={()=> toggleClass('duo-access-greyscale')}><span class="fa fa-barcode"></span> Greyscale</button></li>
    <li class={classes.indexOf('duo-access-highcontrast')>-1 ? 'active' : ''}><button on:click={()=> toggleClass('duo-access-highcontrast')}><span class="fa fa-adjust"></span> High contrast</button></li>
    <li class={classes.indexOf('duo-access-negative')>-1 ? 'active' : ''}><button on:click={()=> toggleClass('duo-access-negative')}><span class="fa fa-eye"></span> Negative contrast</button></li>
    <li class={classes.indexOf('duo-access-underline')>-1 ? 'active' : ''}><button on:click={()=> toggleClass('duo-access-underline')}><span class="fa fa-link"></span> Underline links</button></li>
    <li class={classes.indexOf('duo-access-readable')>-1 ? 'active' : ''}><button on:click={()=> toggleClass('duo-access-readable')}><span class="access-readable">A</span> Readable font</button></li>
    <li><button on:click={()=> reset()}><span class="fa fa-undo"></span> Reset</button></li>
  </ul>
</div>